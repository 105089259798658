import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { GeneralService } from './general.service';
import { rejects } from 'assert';
import { request } from 'http';
import { ERROR_COMPONENT_TYPE } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  /** Request */
  public headers: HttpHeaders;

  /** API Access - ENV */
  public baseUrl: string = 'https://persing.imagineapps.co/';
  //public baseUrl: string = 'http://localhost:8081/';

  constructor(public http: HttpClient, private generalService: GeneralService) {
    // this.baseUrl = environment.baseUrl;
    const _token: string = localStorage.getItem('_token') || '';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: _token,
    });
    // this.headers.append('Content-Type', 'application/json');
    // this.setBearer();
  }

  // =================================
  // SERVICES
  // ================================

  /**
   * Header token
   */
  // private setBearer(): void {
  //   if (_token && !this.headers.has('Authorization')) this.headers.append('Authorization', _token);
  // }

  /**
   * Obtiene una respuesta del servidor
   * @param {Observable} request
   */
  private responseHandler({ request, hide404 }: { request: Observable<any>; hide404?: boolean }): Promise<any> {
    return new Promise((resolve) => {
      request.subscribe(
        (response: Response) => {
          if (response) {
            if (response.status === 200 || response.status === 201) {
              if (response.body['data'] !== undefined && response.body['data'] !== null) resolve(response.body['data']);
              else {
                if (!hide404)
                  this.generalService.handleError({
                    error: '404 Objeto No Encontrado.',
                  });
                resolve(undefined);
              }
            } else {
              this.generalService.handleError({ error: response.body });
              resolve(undefined);
            }
          } else {
            this.generalService.handleResponseNull({});
            resolve(undefined);
          }
        },
        (error) => {
          const _body: any = JSON.parse(error._body || '{}');
          if (_body.message)
            this.generalService.handleError({
              error: `${_body.message}`,
            });
          else this.generalService.handleError({ error });
          resolve(undefined);
        }
      );
    });
  }

  // Services with observables
  public changePassword({ api, data }): Observable<any> {
    return this.http.post(`${this.baseUrl}${api}`, data, {
      headers: this.headers,
    });
  }

  private deleteResponseHandler({ request }) {
    return new Promise((resolve, reject) => {
      request.subscribe((response) => {
        if (response) {
          if (response.status === 200) {
            this.generalService.handleSuccess({
              text: 'Registro eliminado exitosamente',
            });
            resolve(response);
          } else {
            this.generalService.handleError({ error: response.body });
            reject(new Error(response.body));
          }
        } else {
          this.generalService.handleError({ error: 'Sin respuesta' });
          reject(new Error('Sin respuesta'));
        }
      });
    });
  }

  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Promise<any>} respuesta asincrónica
   */
  public get({ api, hide404 }: { api: String; hide404?: boolean }): Promise<any> {
    const request = this.http.get(`${this.baseUrl}${api}`, {
      headers: this.headers,
      observe: 'response',
    });
    return this.responseHandler({ request, hide404: hide404 });
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  public post({ api, data }: { api: String; data: any }): Promise<any> {
    const request = this.http.post(`${this.baseUrl}${api}`, data, {
      headers: this.headers,
      observe: 'response',
    });
    return this.responseHandler({ request });
  }

  /**
   * API Generico DELETE
   * @param {string} api route: api/componente/id
   * @return {Observable<any>} respuesta asincrónica
   */
  public delete({ api }: { api: String }): Promise<any> {
    const request = this.http.delete(`${this.baseUrl}${api}`, {
      headers: this.headers,
      observe: 'response',
    });
    return this.deleteResponseHandler({ request });
  }

  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  public put({ api, data, hide404 }: { api: String; data: any; hide404?: boolean }): Promise<any> {
    const request = this.http.put(`${this.baseUrl}${api}`, data, {
      headers: this.headers,
      observe: 'response',
    });

    return this.responseHandler({ request, hide404: hide404 });
  }
}
